import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"company-view"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"5","lg":"4"}},[_c('company-bio-panel',{attrs:{"company-data":_vm.companyData,"is-plan-upgrade-dialog-visible":_vm.isPlanUpgradeDialogVisible},on:{"update:isPlanUpgradeDialogVisible":function($event){_vm.isPlanUpgradeDialogVisible=$event},"update:is-plan-upgrade-dialog-visible":function($event){_vm.isPlanUpgradeDialogVisible=$event},"refresh":function($event){return _vm.updateCompanyValue($event )}}})],1),_c(VCol,{attrs:{"cols":"12","md":"7","lg":"8"}},[_c(VTabs,{staticClass:"company-tabs",attrs:{"show-arrows":""},model:{value:(_vm.companyTab),callback:function ($$v) {_vm.companyTab=$$v},expression:"companyTab"}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.icon},[_c(VIcon,{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.title))])],1)}),1),_c(VTabsItems,{staticClass:"mt-5 pa-1",attrs:{"id":"company-tabs-content"},model:{value:(_vm.companyTab),callback:function ($$v) {_vm.companyTab=$$v},expression:"companyTab"}},[_c(VTabItem,[_c('employees-list',{on:{"refresh":function($event){return _vm.updateEmployeeValue($event)}}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }