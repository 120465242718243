<template>
  <v-row class="company-bio-panel">
    <!-- company profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <span class="mb-2">{{ companyData.name }} </span>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Company Name:</span>
              <span class="text--secondary">{{ companyData.name || "N/A" }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Total number of employees:</span>
              <span class="text--secondary">{{ companyData.employees_count || 0 }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Email:</span>
              <span class="text--secondary">{{ companyData.email || "N/A" }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Mobile Number:</span>
              <span class="text--secondary">{{ companyData.mobile_number || "N/A" }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Emergency Number:</span>
              <span class="text--secondary">{{ companyData.emergency_number || "N/A" }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Duration:</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">From:</span>
              <span class="text--secondary">{{ companyData.from || "N/A" }} &nbsp;</span>
              <span class="font-weight-medium me-2"> To:</span>
              <span class="text--secondary">{{ companyData.to || "N/A" }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Delivery Days:</span>
              <span
                class="text--secondary"
              >{{
                Array.isArray(companyData.delivery_days) ? companyData.delivery_days.toString() : companyData.delivery_days
              }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Break Time:</span>
              <span class="text--secondary">{{ companyData.break_time || "N/A" }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Delivery Factor:</span>
              <span class="text--secondary">{{ companyData.delivery_factor || "N/A" }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0"
            >
              <span class="font-weight-medium me-2">Latitude:</span>
              <span class="text--secondary">{{ companyData.latitude || "N/A" }}</span>
            </v-list-item>
            
            <v-list-item
              dense
              class="px-0"
            >
              <span class="font-weight-medium me-2">Longitude:</span>
              <span class="text--secondary">{{ companyData.longitude || "N/A" }}</span>
            </v-list-item>

            <!--
            <v-list-item
              dense
              class="px-0"
            >
              <span class="font-weight-medium me-2">City Arabic:</span>
              <span
                class="text--secondary">{{
                  companyData.city && companyData.city.name_ar || "N/A"
                }}</span>
            </v-list-item> -->

            <v-list-item
              dense
              class="px-0"
            >
              <span class="font-weight-medium me-2">City English:</span>
              <span
                class="text--secondary"
              >{{
                companyData.city && companyData.city.name_en || "N/A"
              }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0"
            >
              <span class="font-weight-medium me-2">Street Name:</span>
              <span class="text--secondary">{{ companyData.street_name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0"
            >
              <span class="font-weight-medium me-2">Building Number:</span>
              <span class="text--secondary">{{ companyData.building_number }}</span>
            </v-list-item>

            <v-card-actions class="justify-center mt-3">
              <v-btn
                color="primary"
                class="me-3"
                @click="isBioDialogOpen = !isBioDialogOpen"
              >
                Edit
              </v-btn>
              <v-btn
                color="error"
                outlined
                @click="removeCompany(companyData.id)"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-list>
        </v-card-text>
      </v-card>

      <!-- edit profile dialog data -->
      <company-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :company-data="companyData"
        @refresh="updateValue($event )"
      ></company-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { avatarText, kFormatter } from '@core/utils/filter'
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import useCompaniesList from '../../company-list/useCompaniesList'
import CompanyBioEdit from './CompanyBioEdit.vue'

export default {
  components: {
    CompanyBioEdit,
  },
  props: {
    companyData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('refresh', value)
    },
    removeCompany(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      })
        .then(result => {
          if (result.isConfirmed) {
            store.dispatch('app-company/removeCompany', id).then(() => {})
            Swal.fire('Deleted!', 'entry has been deleted.', 'success')
            this.$router.push({ path: '/apps/company/list' })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },

  setup() {
    const { resolveCompanyStatusVariant, resolveCompanyRoleVariant } = useCompaniesList()

    const isBioDialogOpen = ref(false)

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Users', 'Up to 10GB storage', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      resolveCompanyStatusVariant,
      resolveCompanyRoleVariant,
      avatarText,
      kFormatter,
      resolveCurrentPlanValue,

      isBioDialogOpen,
      standardPlan,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
