<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card :key="companyDataLocal.id" class="company-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Edit Company Information </v-card-title>
      <v-card-text class="text-center mt-n2"> Updating Company details will receive a privacy audit. </v-card-text>

      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyDataLocal.name"
                outlined
                dense
                label="Company Name"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyDataLocal.email"
                outlined
                dense
                label="Email"
                :rules="[validators.required, validators.emailValidator]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyDataLocal.mobile_number"
                outlined
                dense
                label="Mobile Number"
                type="number"
                hide-spin-buttons
                :rules="[validators.required, validators.integerValidator]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyDataLocal.emergency_number"
                outlined
                dense
                label="Emergency Number"
                type="number"
                hide-spin-buttons
                :rules="[validators.required, validators.integerValidator]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <span class="font-weight-medium me-2">Duration:</span>
              <p></p>
              <v-text-field
                v-model="companyDataLocal.from"
                outlined
                dense
                type="date"
                label="From"
                :min="new Date().toISOString().substr(0, 10)"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <span class="font-weight-medium me-2"></span>
              <p></p>
              <v-text-field
                v-model="companyDataLocal.to"
                outlined
                dense
                type="date"
                label="To"
                :min="new Date().toISOString().substr(0, 10)"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="companyDataLocal.break_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="companyDataLocal.break_time"
                    label="Break Time"
                    prepend-icon=""
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    :rules="[validators.required]"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-time-picker
                  v-if="menu2"
                  v-model="time"
                  full-width
                  :rules="[validators.required]"
                  @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="companyDataLocal.delivery_days"
                :items="deliveryDays"
                chips
                label="Delivery Days"
                multiple
                outlined
                dense
              >
              </v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyDataLocal.delivery_factor"
                outlined
                dense
                label="delivery Factor"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <span class="font-weight-medium me-2 p-2">Address:</span>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="companyDataLocal.latitude" outlined dense label="Latitude"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="companyDataLocal.longitude" outlined dense label="Longitude"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="companyDataLocal.street_name" outlined dense label="Street Name"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="companyDataLocal.building_number"
                outlined
                dense
                label="Building Number"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> submit </v-btn>

              <v-btn outlined color="secondary" @click.prevent="$emit('update:is-bio-dialog-open', false)">
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { emailValidator, integerValidator, required } from '@core/utils/validation'
import { mdiClock } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    companyData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      time: null,
      menu2: false,
      modal2: false,
    }
  },

  setup(props, { emit }) {
    const isBillingAddress = ref(true)

    const companyDataLocal = ref({})
    companyDataLocal.value = JSON.parse(JSON.stringify(props.companyData))

    // don't change this ordered array
    const deliveryDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Sat', 'Fri']

    const deliveryDaysKeys = {
      Sun: 1,
      Mon: 2,
      Tue: 3,
      Wed: 4,
      Thu: 5,
      Sat: 6,
      Fri: 7,
    }

    const languageOptions = ['English', 'Spanish', 'Portuguese', 'Russian', 'French', 'German']

    // on form submit
    const onSubmit = async function () {
      companyDataLocal.value.from = new Date(companyDataLocal.value.from)
      companyDataLocal.value.to = new Date(companyDataLocal.value.to)
      companyDataLocal.value.delivery_days = companyDataLocal.value.delivery_days.map(day => deliveryDaysKeys[day])

      try {
        const { data } = await store.dispatch('app-company/editCompany', companyDataLocal.value)

        companyDataLocal.value = data.data.company
        emit('refresh', data.data.company)
      } catch (e) {
        if (e?.response?.status === 404) {
          companyDataLocal.value = {}
        }
      }

      emit('update:is-bio-dialog-open', false)
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        companyDataLocal.value = JSON.parse(JSON.stringify(props.companyData))
      },
    )

    return {
      isBillingAddress,
      deliveryDays,
      languageOptions,
      countries,
      companyDataLocal,
      onSubmit,
      icons: {
        mdiClock,
      },
      validators: { required, emailValidator, integerValidator },
    }
  },
}
</script>
