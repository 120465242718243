<template>
  <div id="company-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <company-bio-panel
          :company-data="companyData"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
          @refresh="updateCompanyValue($event )"
        ></company-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="companyTab"
          show-arrows
          class="company-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="company-tabs-content"
          v-model="companyTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <employees-list
              @refresh="updateEmployeeValue($event)"
            ></employees-list>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { mdiAccountOutline } from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import EmployeesList from '../../employees/employees-list/EmployeesList.vue'
import companyStoreModule from '../companyStoreModule'
import CompanyBioPanel from './company-bio-panel/CompanyBioPanel.vue'

export default {
  components: {
    CompanyBioPanel,
    EmployeesList,
  },
  methods: {
    updateCompanyValue(value) {
      this.companyData = value
    },
    updateEmployeeValue(data) {
      // push stored employee to companyData
      this.companyData.employees.push(data)
      console.log('this.companyData.employees',this.companyData.employees);
    },
  },
  setup() {
    const COMPANY_APP_STORE_MODULE_NAME = 'app-company'

    // Register module
    if (!store.hasModule(COMPANY_APP_STORE_MODULE_NAME)) {
      store.registerModule(COMPANY_APP_STORE_MODULE_NAME, companyStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMPANY_APP_STORE_MODULE_NAME)) store.unregisterModule(COMPANY_APP_STORE_MODULE_NAME)
    })

    const companyData = ref({})
    const companyTab = ref(null)
    const isPlanUpgradeDialogVisible = ref(false)

    store
      .dispatch('app-company/fetchCompany', { id: router.currentRoute.params.id })
      .then(response => {
        companyData.value = response.data.data
        console.log('companyData.value',companyData.value);
      })
      .catch(error => {
        if (error.response.status === 404) {
          companyData.value = {}
        }
      })

    const tabs = [{ icon: mdiAccountOutline, title: 'Employees' }]

    return {
      tabs,
      companyTab,
      isPlanUpgradeDialogVisible,
      companyData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
